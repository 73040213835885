var images = document.querySelectorAll('.examples__slider-top:not(.swiper-container-thumbs) img');

for (const image of images) {
	image.addEventListener('click', function() {
		let modalImg = document.querySelector('.modal-img'),
			srcImd;
		
		console.clear();
		if (this.getAttribute('data-big-src')) {
			srcImd = this.getAttribute('data-big-src').replace(/[.].*/gm, '');	
		} else {
			console.log('Пропиши у картинок на которые нажимаешь путь в атрибут data-big-src(в хорошем качестве) и тогда они откроются на весь экран в норм качестве');
			srcImd = this.getAttribute('src').replace(/[.].*/gm, '');	
		}
		modalImg.classList.add('show');
		document.body.style.overflow = 'hidden';
		modalImg.querySelector('img').setAttribute('src', `${srcImd}.jpg`);
		modalImg.querySelector('source').setAttribute('srcset', `${srcImd}.webp`);
		modalImg.addEventListener('click', function() {
			modalImg.classList.remove('show');
			document.body.style.overflow = '';
		});
	});	
}